<template>
  <div>
    <router-view/>
    <footer>Image: SSC</footer>
  </div>
</template>

<style>
html, body {
  height: 100%;
  padding: 0;
  overflow: auto;
}

* {
  z-index: 1;
}

body::before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/assets/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  filter: brightness(40%);
  z-index: 0;
  margin: 0;
  overflow: auto;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  margin: 0.5em;
}

p {
  color: white;
  font-weight: bold;
  margin: 0.5em;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

footer {
  position: fixed;
  bottom: 0;
  right: 0;
  color: white;
  z-index: 0;
  font-size: 0.5rem;
  padding: 0.2rem;
}

.button {
  border: 2px solid white;
  border-radius: 0.5rem;
  background-color: transparent;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 2rem 0 2rem 0;
}

.button:hover {
  background-color: white;
  color: black;
}

.button a {
  color: white;
  text-decoration: none;
}

.button a:hover {
  color: black;
}

a {
  color: #7597ba;
  text-shadow: white;
}

@media only screen and (min-aspect-ratio: 1920/1078) {
  body {
    background-size: 100% auto ;
  }
}

@media only screen and (min-width: 600px) {
}

@media only screen and (min-width: 768px) {
  body {
    margin: 0;
  }

  h1 {
    font-size: 6rem;
    margin: 0.3em;
  }

  footer {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .button {
    margin: 1rem;
  }
}
</style>
