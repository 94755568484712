<template>
  <div class="home">
    <h1>Fly Your Name is closed!</h1>
    <div class="info">
        <p>We are transferring your names to the experiment.</p>
        <p>FINIX will launch on the upcoming REXUS flight.</p>
        <p id="countdown"></p>
        <p>Come back again for the next Fly your name launch!</p>
    </div>
    <div class="wrapper">
      <div class="links">
        <a href="https://www.ksat-stuttgart.de/de/" target="_blank"><img src="/assets/ksat.svg" /></a>
        <p>Click here to see more of KSat e.V. and all of our other projects</p>
      </div>
      <div class="links">
        <a href="https://www.ksat-stuttgart.de/de/finix/" target="_blank"><img src="/assets/logo.png" /></a>
        <p>Click here to get to our FINIX project page and to see when your name will launch</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    var countDownDate = new Date(2025, 2, 12, 4, 0, 0, 0).getTime();

    var el = document.getElementById("countdown");

    var x = setInterval(function() {

        var now = new Date().getTime();

        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        el.innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

        if (distance < 0) {
            clearInterval(x);
            el.innerHTML = "LAUNCH!! FeerAS was launched on a REXUS rocket!";
        }
    }, 1000);
  }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.info {
    margin: 2rem;
}

.info p {
    font-size: 5;
}

#countdown {
    font-size: 2rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.links {
  width: 50%;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.links img {
  width: 12rem;
  border-radius: 6rem;
}

.links img:hover {
  box-shadow: 0 0 10rem 1rem white;
  -moz-box-shadow: 0 0 10rem 1rem #fff;
  -webkit-box-shadow: 0 0 10rem 1rem #fff;
  -o-box-shadow: 0 0 10rem 1rem #fff;
}

.links p {
  font-weight: bold;
  margin: 1rem 0.5rem;
}

@media screen and (min-width: 768px) {

  .wrapper {
    flex-direction: row;
    align-items: flex-start;
  }
}

@media only screen and (min-width: 1600px) {

    #countdown {
        font-size: 4rem;
    }

  .links img {
    width: 20rem;
    border-radius: 10rem;
  }

  .info {
    margin: 6rem;
  }
}
</style>
