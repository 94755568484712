import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Ticket from '../views/Ticket.vue'
import TermsOfUse from '../views/Terms.vue'
import Launch from '../views/Launch.vue'

const endDate = new Date(2025, 2, 7, 18, 0, 0, 0);

var routes;

if(Date.now() < endDate.getTime()){
  
  routes = [
    {
        path: '/',
        name: 'Home',
      component: Home,
    },
    {
      path: '/lift-off/:fullName',
      name: 'Ticket',
      component: Ticket,
      props: true
    },
    {
      path: '/terms-of-use',
      name: 'TermsOfUse',
      component: TermsOfUse,
    }
  ]

} else {
  routes = [
    {
      path: '/',
      name: 'Launch',
      component: Launch
    }
  ]
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})

export default router
