<template>
  <div>
    <router-link
        to="/"
        custom
        v-slot="{ navigate }"
    >
        <button class="button" @click="navigate" role="link">Go back</button>
    </router-link>
    <div  class="terms">
        <h1>Terms of use</h1>
        <div class="text">
            <p>We use the entered name for following things:</p>
            <ul>
                <li><p>Creation of a personal "Fly Your Name to Space" certificate for download by the user.</p></li>
                <li><p>The name is stored on the flight hardware and flown on the REXUS 34 rocket.</p></li>
            </ul>

            <p>After completion of the experiment, all data will be deleted, both from the flown storage media as well as backup copies and the certificates.
            Under no circumstances will the data be passed on to third parties. <br /> <br /></p>

            <p>Irresprective of this, it is always possible to request the deletion of your own data from <a href= "mailto:kontakt@ksat-stuttgart.de">kontakt@ksat-stuttgart.de</a>. <br /> <br /></p>
            
            <p>Responsible for data collection is <a href="https://ksat-stuttgart.de/de/impressum/">KSat e.V.</a>.</p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfUse',
  computed: {
    baseURL() {
      return process.env.VUE_APP_BASE_URL || "";
    }
  },
  methods: {
    async submit() {
      if (this.fullName.trim()) {
        await fetch(this.baseURL + '/api/user', {
          headers: {
            "Content-type": "application/json"
          },
          method: "POST",
          body: JSON.stringify({
            name: this.fullName
          })
        });
        this.$router.push({ name: "Ticket", params: { fullName: this.fullName } });
      }
    }
  }
}
</script>

<style scoped>
.terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.button {
    position: fixed;
    left: 8rem;
    top: 4rem;
    z-index: 1;
}

h1 {
    left: 0;
    right: 0;
}

.text {
    color: white;
    text-align: left;
    width: 100%;
}

@media screen and (max-width: 992px) {
    .button {
        position: relative;
        left: 0;
        right: 0;
        top: 1rem;
    }
}
</style>
