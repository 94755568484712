<template>
  <div class="home">
    <h1>Fly Your Name!</h1>
    <input v-model="fullName" type="text" class="form__input" id="name" placeholder="Full Name" required="" autocomplete="off" />
    <button class="button" type="button" @click="submit">Launch</button>
    <p>By clicking this button you accept that your name will launch on a Rexus sounding rocket with FINIX <br /> also you are accepting our <router-link to="/terms-of-use">terms of use</router-link></p>
    <div class="wrapper">
      <div class="links">
        <a href="https://www.ksat-stuttgart.de/de/" target="_blank"><img src="/assets/ksat.svg" /></a>
        <p>Click here to see more of KSat e.V. and all of our other projects</p>
      </div>
      <div class="links">
        <a href="https://www.ksat-stuttgart.de/de/finix/" target="_blank"><img src="/assets/logo.png" /></a>
        <p>Click here to get to our FINIX project page and to see when your name will launch</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: function () {
    return {
      fullName: "",
    }
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_BASE_URL || "";
    }
  },
  methods: {
    async submit() {
      if (this.fullName.trim()) {
        await fetch(this.baseURL + '/api/user', {
          headers: {
            "Content-type": "application/json"
          },
          method: "POST",
          body: JSON.stringify({
            name: this.fullName
          })
        });
        this.$router.push({ name: "Ticket", params: { fullName: this.fullName } });
      }
    }
  }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.home input {
  width: 80%;
  height: 1em;
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.5rem;
}

.home button {
  width: auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.links {
  width: 50%;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.links img {
  width: 12rem;
  border-radius: 6rem;
}

.links img:hover {
  box-shadow: 0 0 10rem 1rem white;
  -moz-box-shadow: 0 0 10rem 1rem #fff;
  -webkit-box-shadow: 0 0 10rem 1rem #fff;
  -o-box-shadow: 0 0 10rem 1rem #fff;
}

.links p {
  font-weight: bold;
  margin: 1rem 0.5rem;
}

@media screen and (min-width: 768px) {
  .home input {
    width: 50%;
  }

  .wrapper {
    flex-direction: row;
    align-items: flex-start;
  }
}

@media only screen and (min-width: 1600px) {
  .links img {
    width: 20rem;
    border-radius: 10rem;
  }
}
</style>
