<template>
  <div class="ticket">
    <h1>Congrats {{ fullName }}! Your name will fly with FINIX on the REXUS 34 rocket!</h1>
    <div class="pdf-preview-window">
      <object v-if="!isSafari" id="pdf-object" :data="file" type="application/pdf">
        <p>Your browser doesn't support pdf viewing.</p>
      </object>
      <button class="button" @click="downloadFile">Download</button>
    </div>
  </div>
</template>

<script>
import { PDFDocument } from 'pdf-lib'

export default {
  name: 'Ticket',
  created() {
    this.fullName = this.$route.params.fullName
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },
  mounted() {
    this.fillAndFlattenForm()
  },
  data: function () {
    return {
      pdfURL: "./assets/Fly_your_name_ticket_FINIX.pdf",
      file: null,
      url: null,
      fullName: "",
      isSafari: false
    }
  },
  methods: {
    async fillAndFlattenForm() {
      const formPdfBytes = await fetch(this.pdfURL).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(formPdfBytes)
      const form = pdfDoc.getForm()

      form.getFields()[0].setText(this.fullName)

      form.flatten()

      const pdfBytes = await pdfDoc.save()

      this.file = URL.createObjectURL(new File([pdfBytes], "Fly_your_name_ticket_FINIX_" + this.fullName.replace(/ /g, "_") + ".pdf", { type: 'application/pdf;base64' }));
    },
    downloadFile() {
      const elem = window.document.createElement('a');
      elem.href = this.file;
      elem.download = "Fly_your_name_ticket_FINIX_" + this.fullName.replace(/ /g, "_") + ".pdf";
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 1.5rem;
}

.ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.pdf-preview-window {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#pdf-object {
  display: none;
}

@media (min-width: 1280px) {
  h1 {
    font-size: 2.5rem;
  }

  .pdf-preview-window {
    width: 66em;
    height: 34em;
  }

  #pdf-object {
    display: block;
    width: 100%;
    height: 100%;
  }
}

</style>